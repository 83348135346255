import { createBrowserRouter } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { AuthRoute, ProtectedRoute } from './app/Layout/ProtectedRoute';
import { FallBack } from './app/pages/Common/FallBack';
import OnlyHeaderLayout from './app/Layout/OnlyHeaderLayout';

const AppLayout = lazy(() => import('./app/Layout/AppLayout'));
const Dashboard = lazy(() => import('./app/pages/Dashboard'));
const Jobs = lazy(() => import('./app/pages/Jobs'));
const NotFound = lazy(() => import('./app/pages/Common/404NotFound'));
const Chatbot = lazy(() => import('./app/pages/Chatbot'));
const SignUp = lazy(() => import('./app/pages/Auth/SignUp'));
const Login = lazy(() => import('./app/pages/Auth/Login'));
const JobForm = lazy(() => import('./app/pages/Jobs/JobForm'));
const AIInterview = lazy(() => import('./app/pages/Interview/aiInterview'));
const Candidates = lazy(() => import('./app/pages/Candidates'));
const JobCandidates = lazy(() => import('./app/pages/Jobs/JobCandidates'));
const CandidateDetails = lazy(() =>
  import('./app/pages/Candidates/CandidateDetails')
);
const InterviewFeedback = lazy(() =>
  import('./app/pages/Interview/InterviewFeedback')
);
const CandidateRegister = lazy(() =>
  import('./app/pages/Candidates/CandidateRegister')
);

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<FallBack />}>
        <ProtectedRoute>
          <AppLayout />
        </ProtectedRoute>
      </Suspense>
    ),
    caseSensitive: true,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<FallBack />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: 'jobs',
        children: [
          {
            path: '',
            element: (
              <Suspense fallback={<FallBack />}>
                <Jobs />
              </Suspense>
            ),
          },
          {
            path: 'update/:id',
            element: (
              <Suspense fallback={<FallBack />}>
                <JobForm />
              </Suspense>
            ),
          },
          {
            path: 'candidate-jobs/:id',
            element: (
              <Suspense fallback={<FallBack />}>
                <JobCandidates />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'candidates',
        children: [
          {
            path: '',
            element: (
              <Suspense fallback={<FallBack />}>
                <Candidates />
              </Suspense>
            ),
          },
          {
            path: 'candidate-profile/:id',
            element: (
              <Suspense fallback={<FallBack />}>
                <CandidateDetails />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'chatbot',
        element: (
          <Suspense fallback={<FallBack />}>
            <Chatbot />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<FallBack />}>
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/signup',
    element: (
      <Suspense fallback={<FallBack />}>
        <AuthRoute>
          <SignUp />
        </AuthRoute>
      </Suspense>
    ),
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<FallBack />}>
        <AuthRoute>
          <Login />
        </AuthRoute>
      </Suspense>
    ),
  },
  {
    path: '/interview-feedback/:id',
    element: (
      <Suspense fallback={<FallBack />}>
        <OnlyHeaderLayout>
          <InterviewFeedback />
        </OnlyHeaderLayout>
      </Suspense>
    ),
  },
  {
    path: '/candidate-register/',
    element: (
      <Suspense fallback={<FallBack />}>
        <OnlyHeaderLayout>
          <CandidateRegister />
        </OnlyHeaderLayout>
      </Suspense>
    ),
  },
  // {
  //   path: '/ai-interview',
  //   element: (
  //     <Suspense fallback={<FallBack />}>
  //       <AIInterview />
  //     </Suspense>
  //   ),
  // },
  {
    path: '*',
    element: (
      <Suspense fallback={<FallBack />}>
        <NotFound />
      </Suspense>
    ),
  },
]);

// import './App.css';
import React, { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './Router';
import { LoaderProvider } from './app/Config/loaderContext';
import { Loader } from './app/pages/Common/Loader';
import { useGlobalLoader } from './app/Config/loaderState';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Chart from 'chart.js/auto';

const App = () => {
  const [loading, setLoading] = useState(false);
  useGlobalLoader(setLoading);
  return (
    <>
      <ToastContainer theme='colored' />
      <LoaderProvider>
        {loading && <Loader />}
        <RouterProvider router={router} />
      </LoaderProvider>
    </>
  );
};

export default App;
